export interface FeatureFlag {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
}

export enum FeatureFlags {
  VehiclePurchase = "VehiclePurchase",
  C2CFinancing = "C2CFinancing",
  SRPPromoBanner = "SRPPromoBanner",
  AftermarketReminderModal = "AftermarketReminderModal",
  VoPayIco = "VoPayIco",
  VoPayC2C = "VoPayC2C",
  AftermarketQuoteMethod = "AftermarketQuoteMethod",
  AftermarketCostRange = "AftermarketCostRange",
  InovatecFinancing = "InovatecFinancing",
  AftermarketMonthlyPayment = "AftermarketMonthlyPayment",
  VoPayInteracIco = "VoPayInteracIco",
  VoPayInteracC2C = "VoPayInteracC2C",
  HideRightRideLocations = "HideRightRideLocations",
  IcoAppraisalPhoneVerification = "IcoAppraisalPhoneVerification",
}
